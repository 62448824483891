import "core-js/modules/es.json.stringify.js";
import { isIframeEnv } from './../service/check-environment';
import { Wallet } from 'ethers';
import Auth0 from 'auth0-js';
import i18n from '@/plugins/i18n';
import { ElMessageBox } from 'element-plus';
import { Keyset } from '@unipasswallet/keys';
import api from '@/service/backend';
import router from '@/plugins/router';
import blockchain from '@/service/blockchain';
import Tss from '@/utils/tss';
import { getAccountKeysetJson, getIdTokenWithOutSig } from '@/utils/rbac';
import { useUserStore } from '@/store/user';
import { getOAuthUserInfo, LocalStorageService } from './storages';
import { safeDecryptKeyStore, safeEncryptKeystore } from '@/utils/oauth/keystore';
import { genGoogleOAuthUrl } from '@/utils/oauth/google-oauth';
import { AUTH0_CONFIG, getConnectionByProvider } from '@/utils/oauth/auth0-config';
import { upError, upGA } from '@/utils/useUniPass';
import { encryptSessionKey } from '@/utils/session-key';
import DB from './index-db';
import { useKMSLoginStore } from './kms-login';
import { AccountKeyType, OAuthProvider } from '@/service/enum';
import { handleRedirectEnvAfterOAuth } from '@/utils/oauth/check-authorization';
import { getWeb3AuthPrivateKey, isWeb3AuthKeyType } from '@/service/web3auth';
const {
  t: $t
} = i18n.global;
export const useOAuthLoginStore = defineStore({
  id: 'OAuthLoginStore',
  state: () => {
    return {
      auth0: new Auth0.WebAuth(AUTH0_CONFIG),
      connectType: 'both',
      email: '',
      password: '',
      confirmPassword: '',
      isPending: false,
      auth0EmailLoading: false,
      passwordLoading: false
    };
  },
  actions: {
    changeConnectType(type) {
      this.connectType = type;
      sessionStorage.connectType = type;
    },

    loginWithGoogle() {
      window.location.href = genGoogleOAuthUrl();
    },

    initAccountStatus(isPending) {
      this.isPending = isPending;
    },

    async auth0Login(auth0Params) {
      const userStore = useUserStore();
      const useKMSStore = useKMSLoginStore();
      const {
        prompt = 'login',
        email,
        nonce,
        scope
      } = auth0Params;
      let provider = auth0Params.provider;
      if (provider !== OAuthProvider.APPLE) this.auth0EmailLoading = true;

      const _email = this.email || email;

      if (_email && !nonce && !scope && provider !== OAuthProvider.APPLE) {
        var _res$data;

        const res = await api.checkProvider(_email, LocalStorageService.get('UP_CONNECT_SOURCE'));

        if (!res.ok) {
          this.auth0EmailLoading = false;
          return;
        }

        provider = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.provider;

        if (isIframeEnv() && provider !== OAuthProvider.CUSTODIAL) {
          ElMessageBox.alert($t('DisableInIframeEnv'), $t('Notification'), {
            confirmButtonText: $t('Confirm'),
            center: true,
            showClose: false,
            showCancelButton: false
          });
          this.auth0EmailLoading = false;
          this.email = '';
          return;
        }

        if (res.ok && provider === OAuthProvider.GOOGLE) {
          ElMessageBox.alert($t('UseGoogleOauth'), $t('Notification'), {
            confirmButtonText: $t('SignWithGoogle'),
            center: true
          }).then(() => {
            upGA('login_click_change_to_google');
            window.location.href = genGoogleOAuthUrl(_email);
          }).catch(() => {});
          this.auth0EmailLoading = false;
          return;
        }

        if (res.ok && provider === OAuthProvider.CUSTODIAL) {
          // CUSTODIAL account signUp or login
          useKMSStore.verifyCodeForCustodial(_email);
          this.auth0EmailLoading = false;
          this.email = '';
          return;
        }
      } else if (_email && provider === OAuthProvider.CUSTODIAL) {
        // CUSTODIAL account update up-sign-token
        useKMSStore.updateUpSignTokenCustodial(_email);
        this.auth0EmailLoading = false;
        this.email = '';
        return;
      }

      this.auth0.authorize({
        login_hint: _email,
        connection: getConnectionByProvider(provider),
        prompt,
        nonce,
        scope,
        language: JSON.stringify({
          theme: sessionStorage.theme || userStore.theme,
          language: i18n.global.locale.value
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ui_locales: JSON.stringify({
          theme: sessionStorage.theme || userStore.theme,
          language: i18n.global.locale.value
        })
      });
    },

    async encryptSignUp() {
      try {
        var _sessionStorage;

        const oauthUserInfo = getOAuthUserInfo();
        if (!oauthUserInfo) return;
        const {
          email,
          id_token,
          expires_at,
          oauth_provider
        } = oauthUserInfo;
        upGA('register_click_signup', {
          email
        }, oauth_provider);
        const localKeyData = await Tss.generateLocalKey({
          email,
          action: 'signUp'
        });
        if (!localKeyData) return;
        const isWeb3Auth = isWeb3AuthKeyType();

        if (isWeb3Auth) {
          this.password = await getWeb3AuthPrivateKey(oauth_provider, id_token, email);
        }

        const encryptedKeystore = await safeEncryptKeystore(localKeyData.keystore, this.password);
        const {
          encrypted_key,
          aes_key
        } = await encryptSessionKey(localKeyData.keystore);
        const {
          data
        } = await api.getConfig();
        const pepper = Wallet.createRandom().privateKey;
        const keyset = getAccountKeysetJson([], {
          idToken: getIdTokenWithOutSig(id_token)
        }, email, localKeyData.localKeyAddress, data.policyKeysetJson, pepper);
        const keyType = isWeb3Auth ? AccountKeyType.Web3Auth : AccountKeyType.MPC;
        const res = await api.signUpAccount({
          keysetJson: keyset.toJson(),
          masterKey: {
            masterKeyAddress: localKeyData.localKeyAddress,
            keyStore: encryptedKeystore,
            keyType
          },
          pepper,
          source: ((_sessionStorage = sessionStorage) === null || _sessionStorage === void 0 ? void 0 : _sessionStorage.getItem('up_login_source')) || LocalStorageService.get('UP_CONNECT_SOURCE') || 'unipass'
        });
        const accountAddress = blockchain.generateAccountAddress(keyset.hash());

        if (res.ok) {
          sessionStorage.removeItem('up_login_source');
          const {
            address,
            authorization,
            upSignToken
          } = res.data;

          if (address.toLowerCase() === accountAddress.toLowerCase()) {
            const user = {
              email,
              id_token,
              user_key: {
                encrypted_key,
                aes_key
              },
              address: accountAddress,
              oauth_provider,
              expires_at,
              keyset: {
                hash: keyset.hash(),
                masterKeyAddress: localKeyData.localKeyAddress,
                keysetJson: keyset.obscure().toJson(),
                keyType
              },
              keystore: encryptedKeystore
            };
            await DB.setAccountInfo(user);
            await useUserStore().init();
            LocalStorageService.set('OAUTH_INFO', JSON.stringify({ ...oauthUserInfo,
              authorization,
              up_sign_token: upSignToken
            }));
            sessionStorage.newborn = true;
            router.replace('/register/loading');
          } else {
            console.error('signUp account address inconsistent');
          }
        } else {
          console.log('err', res);
          router.replace('/login');
        }
      } catch (e) {
        router.replace('/login');
      }
    },

    async encryptLogin() {
      try {
        const oauthUserInfo = getOAuthUserInfo();
        if (!oauthUserInfo || !oauthUserInfo.unipass_info) return;
        const {
          email,
          id_token,
          expires_at,
          unipass_info,
          oauth_provider
        } = oauthUserInfo;
        upGA('login_click_login', {
          account: unipass_info.address,
          email
        }, oauth_provider);

        if (oauthUserInfo.unipass_info.keyType === AccountKeyType.Web3Auth) {
          this.password = await getWeb3AuthPrivateKey(oauth_provider, id_token, email);
        }

        const decryptedKeyStore = await safeDecryptKeyStore(unipass_info.keystore, this.password);
        const {
          encrypted_key,
          aes_key
        } = await encryptSessionKey(decryptedKeyStore);
        const keyset = Keyset.fromJson(unipass_info.keyset);
        const user = {
          email,
          id_token,
          user_key: {
            encrypted_key,
            aes_key
          },
          address: unipass_info.address,
          oauth_provider,
          expires_at,
          keyset: {
            hash: keyset.hash(),
            masterKeyAddress: keyset.keys[0].address,
            keysetJson: keyset.obscure().toJson(),
            keyType: (unipass_info === null || unipass_info === void 0 ? void 0 : unipass_info.keyType) || AccountKeyType.MPC
          },
          keystore: unipass_info.keystore
        };
        await DB.setAccountInfo(user);
        await useUserStore().init();
        sessionStorage.removeItem('newborn');
        handleRedirectEnvAfterOAuth();
        upGA('login_success', {
          account: unipass_info.address,
          email
        }, oauth_provider);
      } catch (e) {
        if ((e === null || e === void 0 ? void 0 : e.message) === 'invalid password') {
          upError($t('IncorrectPassword'));
          return;
        }

        console.error('login error', e);
        router.replace('/login');
      } finally {
        this.passwordLoading = false;
      }
    }

  }
});